@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}
body {
  margin: 0;
  font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Proxima Nova';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(var(--background-primary));
  scrollbar-width: none;
  -ms-overflow-style: none;
  
}
::-webkit-scrollbar {
  width: 0px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: 249 115 22;  /* orange */
  --primary-lighter: 254 215 170;
  --primary-darker: 234 88 12;

  --secondary: 34 197 94; /* green */
  --secondary-lighter: 187 247 208;
  --secondary-darker: 22 101 52;

  --background-primary: 255 253 251; /* off-white */
  --background-secondary: 243 244 246; /* light gray */

  --header-text: 30 41 59; /* gray */
  --content-text: 100 116 139; 
  --sub-text: 249 250 251;


  /* unchangable for now */
  --accent: 59 130 246; /* blue */
  --accent-lighter: 191 219 254;
  --accent-darker: 30 64 175;

  --alert: 239 68 68; /* red */
  --alert-lighter: 254 202 202;
  --alert-darker: 153 27 27;

  --success: 34 197 94; /* green */
  --success-lighter: 187 247 208;
  --success-darker: 22 101 52;
}